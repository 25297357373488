import { defineStore } from 'pinia'

import type { ShopData } from '~/composables/shops/types/ShopTypes'

export const useShopStore = defineStore('shops', {
  state: () => ({
    shops: [
    ] as ShopData[],
    shopsHome: [] as ShopData[],

  }),
  actions: {
    addShop(shop: ShopData) {
      this.shops.unshift(shop)
    },
    addShopHome(shop: ShopData) {
      this.shopsHome.unshift(shop)
    },
    removeShop(id: number) {
      const index = this.shops.findIndex(shop => shop.seller_id === id)
      if (index !== -1) {
        this.shops.splice(index, 1)
      }
    },
    clearShops() {
      this.shops = []
    }
  },
  getters: {
    get: (state) => (id: number) => {
      return state.shops.find(shop => shop.seller_id === id)
    }
  }
})