import type { ServerResponseCommonType } from '~/types/commons';
import type { ShopPagination, ShopRequestType, ShopData } from './types/ShopTypes';
import { useShopStore } from '~/stores/shopstore';
import type { productPagination, productRequestType } from '../products/types/productTypes';
type TransportShopsDataType = {
    getShops: (options: ShopRequestType, withLoadMore?: boolean, home?: boolean) => Promise<ShopPagination | null>
    getShopById: (id: number) => Promise<ShopData | null>,
    searchShopProducts: (options: { shop_id: number, name: string }, query: productRequestType) => Promise<productPagination | null>
}

export function useShops(): TransportShopsDataType {
    const { $api } = useNuxtApp();
    const shopstore = useShopStore();

    const getShops = async (options: ShopRequestType, withLoadMore?: boolean, home?: boolean): Promise<ShopPagination | null> => {
        const res = await $api<{ shops: ShopPagination }>('/shop/all', {
            method: 'GET',
            params: {
                ...options
            }
        });

        if (res) {
            const shopPagination = res.shops;
            if (!withLoadMore) {
                shopstore.clearShops();
            }

            if (home) {
                shopPagination.data.forEach(shop => {
                    shopstore.addShopHome(shop);
                });
            }
            else {
                shopPagination.data.forEach(shop => {
                    shopstore.addShop(shop);
                });
            }

            return shopPagination;
        }
        return null;
    }

    const searchShopProducts = async (options: { shop_id: number, name: string }, query: productRequestType) => {
        const res = await $api<productPagination>('/shop/search-products', {
            method: 'POST',
            body: {
                ...options
            },
            query
        });

        if (res) {
            return res;

        }
        return null;
    }

    const getShopById = async (id: number): Promise<ShopData | null> => {
        const res = await $api<{ shop: ShopData }>(`/shop/${id}`, {
            method: 'GET'
        });

        if (res) {
            return res.shop;
        }
        return null;
    }

    return {
        getShops,
        getShopById,
        searchShopProducts
    }
}
